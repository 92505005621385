import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';

// :: Components
import ErrorFallback from '../../components/ErrorFallback/ErrorFallback';
import Tutorial from '../../components/Tutorial/Tutorial';

// :: Context
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import { ModalProvider } from '../../contexts/ModalContext';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { useUser } from '../../hooks/api';
import { usePageTutorial } from '../../hooks/api/usePageTutorial';

// :: Lib
import { RolePermissions } from '../../lib/rolePermissions';
import { getMaskedOrganizationName, isUserRoleAdmin } from '../../lib/helpers';
import { ResponseError } from '../../lib/flotiq-client/response-errors';

const ADMIN_ROLES = ['ROLE_ADMIN', 'ROLE_HEADLESS_ADMIN'];

const WrapUserContext = ({ children }) => {
  const { appContext, updateAppContext } = useContext(AppContext);
  const [userContext, setUserContext] = useState({});
  const { space } = useSelectedSpace();
  const [user] = useLocalStorageState('cms.user');
  const [tutorialsStorage] = useLocalStorageState('cms.tutorials');
  const [impersonate] = useLocalStorageState('cms.impersonate');

  const { entity: userData } = useUser(user?.data?.id);

  const isAdmin = useMemo(
    () =>
      (user?.data?.roles || []).findIndex(
        (role) => ADMIN_ROLES.indexOf(role) > -1,
      ) > -1,
    [user?.data?.roles],
  );

  const isRoleAdmin = useMemo(
    () => isUserRoleAdmin(user) && !impersonate,
    [user, impersonate],
  );

  const rolesPermissions = useMemo(() => {
    if (!userData) return;

    const currentPermissions = [];
    (userData?.headlessRoles || []).forEach(({ permissions }) =>
      permissions.forEach((permission) => {
        currentPermissions.push({
          ...permission,
          ctdName: permission.contentTypeDefinition?.name,
        });
      }),
    );
    return currentPermissions;
  }, [userData]);

  const permissions = useMemo(
    () => new RolePermissions(rolesPermissions),
    [rolesPermissions],
  );

  const handleGetUserDetails = useCallback(() => {
    const userDetails = user?.data;
    const token = user?.token;

    updateAppContext?.((prevState) => ({
      ...prevState,
      ...(token && { token }),
      ...(userDetails && { user: userDetails }),
    }));
  }, [user?.data, user?.token, updateAppContext]);

  useOnce(handleGetUserDetails);

  const params = useMemo(() => {
    return {
      limit: 100,
      page: 1,
      hydrate: 1,
      filters: JSON.stringify({
        enabled: { type: 'equals', filter: true },
      }),
    };
  }, []);

  const options = useMemo(
    () => ({
      pause:
        !JSON.parse(process.env.REACT_APP_ENABLE_TUTORIAL) ||
        tutorialsStorage?.run === false,
    }),
    [tutorialsStorage?.run],
  );

  const getErrorFallbackPage = (event) => <ErrorFallback {...event} />;

  const userContextValue = useMemo(
    () => ({
      ...userContext,
      updateUserContext: setUserContext,
      permissions,
      isAdmin,
      isRoleAdmin,
      userStorage: user,
      baseUserEventData: {
        user_id: user?.data?.id,
        organization_id: user?.data?.organization,
        organization_name: getMaskedOrganizationName(
          user?.data?.organization_name,
        ),
        plan_id: user?.data?.limits_plan?.id,
        plan_name: user?.data?.limits_plan?.name,
        space_id: space,
        user_roles: user?.data?.roles,
      },
      userData,
    }),
    [isAdmin, isRoleAdmin, permissions, space, user, userContext, userData],
  );

  const [
    tutorialsData,
    tutorialsIndex,
    tutorialsRun,
    updateStep,
    tutorialErrors,
  ] = usePageTutorial(appContext.id, params, options);

  useEffect(() => {
    if (tutorialErrors instanceof ResponseError) {
      Sentry.captureException(new Error(`Couldn't fetch tutorial`));
    }
  }, [tutorialErrors]);

  const handleTutorialCallback = useCallback(
    (data) => updateStep(data),
    [updateStep],
  );

  return (
    <Sentry.ErrorBoundary fallback={getErrorFallbackPage}>
      <UserContext.Provider value={userContextValue}>
        <ModalProvider>{children ? children : <Outlet />}</ModalProvider>
        {tutorialsData?.length > 0 && (
          <Tutorial
            run={tutorialsRun}
            steps={tutorialsData}
            stepIndex={tutorialsIndex}
            callback={handleTutorialCallback}
          />
        )}
      </UserContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default WrapUserContext;
