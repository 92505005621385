import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { FloppyDiskIcon } from '../../../../images/shapes';
import Loader from '../../../Loader/Loader';

const SaveButton = ({ label, isSaving, form, disabled, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      buttonSize="sm"
      buttonColor="blue"
      iconPosition="start"
      iconImage={
        isSaving ? (
          <Loader size="small" type="spinner-grid" />
        ) : (
          <FloppyDiskIcon className="w-3 min-w-3" />
        )
      }
      disabled={isSaving || disabled}
      {...(form ? { form, type: 'submit' } : {})}
      {...props}
    >
      {label || t('Global.SaveChanges')}
    </Button>
  );
};

export default SaveButton;

SaveButton.propTypes = {
  /**
   * Button label, default "Save changes"
   */
  label: PropTypes.string,
  /**
   * Form id, if not provided form submission should be handled by onClick property
   */
  form: PropTypes.string,
  /**
   * If save form is in progress
   */
  isSaving: PropTypes.bool,
  /**
   * If button is disabled
   */
  disabled: PropTypes.bool,
};

SaveButton.defaultProps = {
  isSaving: false,
  disabled: false,
};
