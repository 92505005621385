import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import UnsplashMedia from '../../../pages/MediaLibrary/Subpages/UnsplashMedia';
import UserMedia from '../../../pages/MediaLibrary/Subpages/UserMedia';
import Tabs from '../../Tabs/Tabs';
import { ModalInstanceContext } from '../../../contexts/ModalContext';
import { twMerge } from 'tailwind-merge';
import AllObjects from '../../../subpages/AllObjects/AllObjects';
import { getTestProps } from '../../../lib/helpers';
import NewMediaContext from '../../../contexts/NewMediaContext';
import { getMediaData } from '../../../lib/newMediaHelpers';
import CancelButton from '../../Button/predefined/CancelButton/CancelButton';
import SaveButton from '../../Button/predefined/SaveButton/SaveButton';

const LIMIT = 16;
const MEDIA_LIMIT = 12;

const LinkObjectContentModal = ({
  relationType,
  selected,
  isMultiple,
  onMediaUpload,
  returnContentObject,
  validateSelected,
  defaultUserMediaFilters,
  testId,
}) => {
  const { t } = useTranslation();
  const isMedia = useMemo(() => relationType === '_media', [relationType]);
  const modalInstance = useContext(ModalInstanceContext);
  const { newMedia, setNewMedia } = useContext(NewMediaContext);

  const [isCleared, setIsCleared] = useState(false);
  const appearedMediaRef = useRef({});
  const [selectedObjects, setSelectedObjects] = useState(selected);

  // Clear new media uploaded if modal was not opened
  useEffect(() => {
    setNewMedia?.([]);
    setIsCleared(true);
  }, [setNewMedia]);

  const selectObject = useCallback(
    (object, type) => {
      setSelectedObjects((prevSelected) => {
        if (!object.id) return prevSelected;
        const objectData = returnContentObject
          ? object
          : {
              type: 'internal',
              dataUrl: `/api/v1/content/${type}/${object.id}`,
            };
        if (!isMultiple)
          return {
            [object.id]: objectData,
          };
        const newSelected = { ...prevSelected };
        if (newSelected[object.id]) delete newSelected[object.id];
        else {
          newSelected[object.id] = objectData;
        }
        return newSelected;
      });
    },
    [isMultiple, returnContentObject],
  );

  useEffect(() => {
    if (!isCleared || !newMedia || (relationType && !isMedia)) return;
    newMedia.forEach((media) => {
      const { card: mediaData, errors, isLoaded = true } = getMediaData(media);
      if (isLoaded && !errors) {
        if (appearedMediaRef.current?.[mediaData.id]) return;
        selectObject(mediaData, '_media');
        appearedMediaRef.current[mediaData.id] = true;
      }
    });
  }, [isCleared, isMedia, newMedia, relationType, selectObject]);

  const allObjectsContent = useCallback(
    (isTabs) => {
      if (isMedia) return null;
      return (
        <AllObjects
          limit={LIMIT}
          contentType={relationType}
          selectedObjects={selectedObjects}
          selectObject={selectObject}
          isTabs={isTabs}
          additionalGridContainerClasses={'h-full px-2'}
          additionalGridClasses={twMerge(
            '!gap-2 sm:!gap-4 !grid-cols-[repeat(auto-fill,_minmax(10rem,_1fr))]',
            'sm:!grid-cols-[repeat(auto-fill,_minmax(13rem,_1fr))]',
          )}
          testId={testId}
        />
      );
    },
    [isMedia, selectedObjects, relationType, selectObject, testId],
  );

  const tabs = useMemo(() => {
    const tabs = [];
    if (!relationType) {
      tabs.push({
        label: t('ContentForm.AllObjects'),
        key: 'all',
        render: () => allObjectsContent(true),
      });
    }
    if (!relationType || isMedia)
      tabs.push(
        ...[
          {
            label: t('Media.Tabs.UploadedFiles'),
            key: 'userMedia',
            render: () => (
              <UserMedia
                mediaOnPageLimit={MEDIA_LIMIT}
                selectMedia={selectObject}
                selectedMedia={selectedObjects}
                multipleUpload={isMultiple}
                hasPanel={false}
                onUpload={onMediaUpload}
                defaultFilters={defaultUserMediaFilters}
                testId={testId}
                additionalGridContainerClasses="h-full md:max-h-[calc(100vh-28rem)] px-2"
                inModal
              />
            ),
          },
          {
            label: t('Media.Tabs.UnsplashFiles'),
            key: 'unsplashMedia',
            render: () => (
              <UnsplashMedia
                mediaOnPageLimit={MEDIA_LIMIT}
                hasPanel={false}
                testId={testId}
                additionalGridContainerClasses="h-full md:max-h-[calc(100vh-28rem)] px-2"
              />
            ),
          },
        ],
      );
    return tabs;
  }, [
    relationType,
    isMedia,
    t,
    allObjectsContent,
    selectObject,
    selectedObjects,
    isMultiple,
    onMediaUpload,
    defaultUserMediaFilters,
    testId,
  ]);

  const resolveModal = useCallback(
    (value) => {
      const isValid = !validateSelected ? true : validateSelected(value);
      if (!isValid) return;

      modalInstance.resolve(value);
      setNewMedia?.([]);
    },
    [modalInstance, setNewMedia, validateSelected],
  );

  const onCancel = useCallback(() => {
    resolveModal(null);
  }, [resolveModal]);

  const onSave = useCallback(() => {
    resolveModal(selectedObjects);
  }, [resolveModal, selectedObjects]);

  return (
    <>
      {tabs.length === 0 ? (
        allObjectsContent(false)
      ) : (
        <Tabs tabs={tabs} testId={testId} />
      )}
      <div
        className="w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5
        border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900"
      >
        <CancelButton
          onClick={onCancel}
          {...getTestProps(testId, 'cancel', 'testId')}
        />
        <SaveButton
          onClick={onSave}
          {...getTestProps(testId, 'save', 'testId')}
        />
      </div>
    </>
  );
};

export default LinkObjectContentModal;

LinkObjectContentModal.propTypes = {
  /**
   * Type of relation objects
   */
  relationType: PropTypes.string,
  /**
   * Selected relations
   */
  selected: PropTypes.object,
  /**
   * If multiple selection is allowed
   */
  isMultiple: PropTypes.bool,
  /**
   * On media upload callback
   */
  onMediaUpload: PropTypes.func,
  /**
   * Callback for validating selected objects before close modal
   */
  validateSelected: PropTypes.func,
  /**
   * Default filters for user media tab
   */
  defaultUserMediaFilters: PropTypes.object,
  /**
   * If a selected object should be select as object data
   */
  returnContentObject: PropTypes.bool,
};

LinkObjectContentModal.defaultProps = {
  relationType: '',
  selected: {},
  isMultiple: false,
  returnContentObject: false,
  onMediaUpload: /* istanbul ignore next */ () => null,
};
