import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PlusDoubleCircleIcon } from '../../../images/shapes';
import TopbarActionButton from './base/TopbarActionButton';

const TopbarDuplicateButton = ({ link, ...props }) => {
  const { t } = useTranslation();

  return (
    <TopbarActionButton
      link={link}
      label={t('Global.Duplicate')}
      iconImage={<PlusDoubleCircleIcon className="w-5" />}
      additionalIconClasses="w-5"
      {...props}
    />
  );
};

export default TopbarDuplicateButton;

TopbarDuplicateButton.propTypes = {
  /**
   * Link to duplicate page
   */
  link: PropTypes.string,
};

TopbarDuplicateButton.defaultProps = {
  link: '',
};
