import { forwardRef, useCallback, useRef } from 'react';
import { Popover, Whisper, Button, Dropdown } from 'rsuite';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../../lib/helpers';
import { EllipsisIcon } from '../../../images/shapes';
import { popoverUIMenuClasses } from '../../Popover/Popover';
import PopoverItem from '../../Popover/PopoverItem/PopoverItem';

const MenuPopover = forwardRef(
  (
    { menuItems, rowData, handleItemOnClick, ignoreIcons, testId, ...rest },
    ref,
  ) => (
    <Popover ref={ref} {...rest} full arrow={false}>
      <Dropdown.Menu className={popoverUIMenuClasses + ' !py-0'}>
        {menuItems.map((item, index) => (
          <PopoverItem
            key={item.id || item.label || index}
            onClick={() => handleItemOnClick(item, rowData)}
            disabled={item.disabled}
            icon={!ignoreIcons ? item.icon : null}
            label={item.label}
            link={item.link}
            {...getTestProps(testId, `${index}-item`)}
          />
        ))}
      </Dropdown.Menu>
    </Popover>
  ),
);

const DataGridActionMenu = ({ menuItems, rowData, ignoreIcons, testId }) => {
  const ref = useRef();
  const handleItemOnClick = useCallback((item, rowData) => {
    // Case: On click when defined
    if (item?.onClick) {
      item.onClick(rowData);
    }
    // Case: Close action modal
    ref.current.close();
  }, []);

  return (
    <Whisper
      placement="autoVerticalStart"
      controlId="control-id-with-dropdown"
      trigger="click"
      ref={ref}
      speaker={
        <MenuPopover
          menuItems={menuItems}
          rowData={rowData}
          handleItemOnClick={handleItemOnClick}
          ignoreIcons={ignoreIcons}
          testId={testId}
        />
      }
    >
      <Button
        className={twMerge(
          'group flex justify-center items-center p-0 ',
          'focus:outline-none dark:focus:bg-gray-700 dark:active:bg-gray-700 transition duration-250 ease-in-out',
          'leading-none rounded-full font-medium w-7 2xl:w-8 h-7 2xl:h-8 dark:hover:bg-gray-700',
        )}
        {...getTestProps(testId, 'button')}
      >
        <EllipsisIcon className="w-4 2xl:w-5 h-4 2xl:h-5 text-slate-400 group-hover:text-blue" />
      </Button>
    </Whisper>
  );
};

DataGridActionMenu.propTypes = {
  /**
   * Action menu items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      icon: PropTypes.node,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  /**
   * If icons in menu should be hidden
   */
  ignoreIcons: PropTypes.bool,
  /**
   * Action menu test id
   */
  testId: PropTypes.string,
};

DataGridActionMenu.defaultProps = {
  ignoreIcons: false,
  testId: '',
};

export default DataGridActionMenu;
