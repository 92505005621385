import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Popover, Transition } from '@headlessui/react';
import { getTestProps } from '../../lib/helpers';

export const popoverUIMenuClasses =
  'px-0 bg-white dark:bg-gray-900 text-zinc-600 dark:text-gray-200 shadow-lg rounded-lg ' +
  'border-2 border-slate-200 dark:border-slate-700 overflow-hidden';

const PopoverComponent = ({
  popoverButton,
  popoverPanelPlacement,
  popoverPhonePanelPlacement,
  content,
  additionalClasses,
  popoverButtonProps,
  additionalPopoverPanelClasses,
  testId,
}) => {
  const placementReset =
    'top-auto left-auto right-auto bottom-auto translate-x-0 translate-y-0 m-0';

  const popoverPanelPlacementClasses = useMemo(
    () => ({
      topLeft: twMerge(placementReset, 'left-0 bottom-full mb-2'),
      topCenter: twMerge(
        placementReset,
        'left-1/2 -translate-x-1/2 bottom-full mb-2',
      ),
      topRight: twMerge(placementReset, 'right-0 bottom-full mb-2'),
      rightTop: twMerge(placementReset, 'left-full bottom-0 ml-2'),
      rightCenter: twMerge(
        placementReset,
        'left-full top-1/2 -translate-y-1/2 ml-2',
      ),
      rightBottom: twMerge(placementReset, 'left-full top-0 ml-2'),
      bottomLeft: twMerge(placementReset, 'left-0 top-full mt-2'),
      bottomCenter: twMerge(
        placementReset,
        'left-1/2 -translate-x-1/2 top-full mt-2',
      ),
      bottomRight: twMerge(placementReset, 'right-0 top-full mt-2'),
      leftTop: twMerge(placementReset, 'bottom-0 right-full mr-2'),
      leftCenter: twMerge(
        placementReset,
        'top-1/2 -translate-y-1/2 right-full mr-2',
      ),
      leftBottom: twMerge(placementReset, 'top-0 right-full mr-2'),
    }),
    [],
  );

  const basePanelPlacementClasses = useMemo(
    () =>
      popoverPanelPlacementClasses[popoverPanelPlacement]
        .split(' ')
        .map((el) => `lg:${el}`)
        .join(' '),
    [popoverPanelPlacementClasses, popoverPanelPlacement],
  );

  return (
    <Popover
      className={twMerge('relative w-fit z-20', additionalClasses)}
      {...getTestProps(testId, 'container')}
    >
      <Popover.Button
        className="focus:outline-none focus-visible:outline-none"
        {...getTestProps(testId, 'button')}
        {...popoverButtonProps}
      >
        {popoverButton}
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={twMerge(
            'px-0 bg-white dark:bg-gray-900 text-zinc-600 dark:text-gray-200 shadow-lg rounded-lg',
            'absolute border-2 border-slate-200 dark:border-slate-700 overflow-hidden',
            basePanelPlacementClasses,
            popoverPanelPlacementClasses[popoverPhonePanelPlacement],
            additionalPopoverPanelClasses,
          )}
          {...getTestProps(testId, 'popover')}
        >
          {content}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default PopoverComponent;

PopoverComponent.propTypes = {
  /**
   * Popover button
   */
  popoverButton: PropTypes.node,
  /**
   * Popover panel placement
   */
  popoverPanelPlacement: PropTypes.oneOf([
    'topLeft',
    'topCenter',
    'topRight',
    'rightTop',
    'rightCenter',
    'rightBottom',
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
    'leftTop',
    'leftCenter',
    'leftBottom',
  ]),
  /**
   * Popover panel placement for phones
   */
  popoverPhonePanelPlacement: PropTypes.oneOf([
    'topLeft',
    'topCenter',
    'topRight',
    'rightTop',
    'rightCenter',
    'rightBottom',
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
    'leftTop',
    'leftCenter',
    'leftBottom',
  ]),
  /**
   * Popover content
   */
  content: PropTypes.any.isRequired,
  /**
   * Additional CSS classes for component wrapper
   */
  additionalClasses: PropTypes.string,
  /**
   * Additional CSS classes for popover panel
   */
  additionalPopoverPanelClasses: PropTypes.string,
};

PopoverComponent.defaultProps = {
  additionalClasses: '',
  additionalPopoverPanelClasses: '',
  content: [],
  popoverPanelPlacement: 'rightBottom',
  popoverPhonePanelPlacement: 'topRight',
};
