import { useCallback, useContext, useId, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { ModalInstanceContext } from '../../contexts/ModalContext';
import ContentObjectForm from '../../form/ContentObjectForm/ContentObjectForm';
import { usePluginsSettings } from '../../hooks/api';
import { getTestProps } from '../../lib/helpers';
import CancelButton from '../../components/Button/predefined/CancelButton/CancelButton';
import SaveButton from '../../components/Button/predefined/SaveButton/SaveButton';

const HOOKS_PARAMS = {
  limit: 10000,
  page: 1,
};

const SchemaModalContent = ({ form, testId }) => {
  const { initialData, schema, options, labels } = form;

  const modalInstance = useContext(ModalInstanceContext);
  const formId = useId();
  const [isSaving, setIsSaving] = useState(false);

  const { data: userPlugins } = usePluginsSettings(HOOKS_PARAMS);

  const closeModal = useCallback(
    (values) => {
      modalInstance.resolve(values);
    },
    [modalInstance],
  );

  const handleSubmit = useCallback(
    async (values) => {
      setIsSaving(true);

      if (options?.onSubmit) {
        const result = await options.onSubmit(values, modalInstance);

        setIsSaving(false);
        return result;
      } else {
        modalInstance.resolve(values);

        return [values, null];
      }
    },
    [modalInstance, options],
  );

  return (
    <>
      <ContentObjectForm
        contentObject={initialData}
        contentType={{ ...schema, nonCtdSchema: true }}
        onSubmit={handleSubmit}
        onValidate={options?.onValidate}
        disabledBuildInValidation={options?.disbaledBuildInValidation}
        hasInitialData={!!initialData}
        formId={formId}
        userPlugins={userPlugins}
        disabled={isSaving}
        additionalFormClasses="pb-0 md:space-y-3"
        isFullSize
        isEditing
        {...getTestProps(testId, 'co-form', 'testId')}
      />
      <div
        className={twMerge(
          'w-full fixed left-0 bottom-0 flex items-center justify-between p-3 md:px-10 space-x-5',
          'border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900',
        )}
      >
        <CancelButton
          onClick={() => closeModal(null)}
          disabled={isSaving}
          label={labels?.cancel}
          {...getTestProps(testId, 'cancel', 'testId')}
        />
        <SaveButton
          form={formId}
          isSaving={isSaving}
          label={labels?.ok}
          {...getTestProps(testId, 'submit', 'testId')}
        />
      </div>
    </>
  );
};

export default SchemaModalContent;

SchemaModalContent.propTypes = {
  /**
   * Form data to render
   */
  form: PropTypes.object.isRequired,
  /**
   * Form test id
   */
  testId: PropTypes.string,
};

SchemaModalContent.defaultProps = {
  testId: '',
};
