import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { getFormatedSize, getTestProps } from '../../../lib/helpers';
import TypeCardRow from './TypeCardRow';
import StatusPill from '../../../components/StatusPill/StatusPill';
import { CheckCircleIcon, CloseIcon } from '../../../images/shapes';

const panelColor = {
  lime: 'lime',
  blue: 'blue',
  green: 'green',
  orange: 'orange',
  disabled: 'gray',
};

const SpaceTypeCard = ({
  name,
  price,
  color,
  selected,
  plan,
  handleSelected,
  additionalClasses,
  disabled,
  limit,
  isCurrentPlan,
  testId,
  isExpanded,
  count,
}) => {
  const { t } = useTranslation();
  const [renderedItems, setRenderedItems] = useState([]);

  const planDetails = useMemo(() => {
    const bodyDetails = [
      // General

      {
        id: `file-quota-${plan.name}`,
        group: 'General',
        value: getFormatedSize(plan.fileQuota, 'MB', 'Custom'),
        title: t('Spaces.FileQuota'),
      },
      {
        id: `object-types-${plan.name}`,
        group: 'General',
        value: getFormatedSize(plan.ctdLimit, '', 'Unlimited'),
        title: t('PlanCard.ContentTypes'),
      },
      {
        id: `objects-limits-${plan.name}`,
        group: 'General',
        value: getFormatedSize(plan.ctoLimit, 'compact-number', 'Custom'),
        title: t('PlanCard.ContentObjects'),
      },
      {
        id: `max-asset-size-${plan.name}`,
        group: 'General',
        value: getFormatedSize(plan.maxAssetSize, 'MB', 'Custom'),
        title: t('PlanCard.AssetUploadSize'),
      },
      {
        id: `bandwidth-limit-${plan.name}`,
        group: 'General',
        value: getFormatedSize(plan.bandwidthLimit, 'GB', 'Custom'),
        title: t('Plans.Form.BandwidthLimit'),
      },

      // Webhooks

      {
        id: `webhooks-limit-${plan.name}`,
        group: 'Webhooks',
        value: getFormatedSize(
          plan.webhooksLimit,
          'compact-number',
          'Unlimited',
        ),
        title: t('PlanCard.WebhookQuota'),
      },
      {
        id: `hosted-webhooks-limit-${plan.name}`,
        group: 'Webhooks',
        value: getFormatedSize(
          plan.hostedWebhooksLimit,
          'compact-number',
          'Unlimited',
        ),
        title: t('PlanCard.HostedWebhooks'),
      },

      // Plugins

      {
        id: `official-plugins-limit-${plan.name}`,
        group: 'Plugins',
        value: getFormatedSize(
          plan.officialPluginsLimit,
          'compact-number',
          'Unlimited',
        ),
        title: t('PlanCard.OfficialFlotiqPlugins'),
      },
      {
        id: `custom-plugins-limit-${plan.name}`,
        group: 'Plugins',
        value: getFormatedSize(
          plan.customPluginsLimit,
          'compact-number',
          'Unlimited',
        ),
        title: t('PlanCard.CustomPlugins'),
      },

      // API Access

      {
        id: `api-calls-limit-${plan.name}`,
        group: 'API Access',
        value: getFormatedSize(plan.apiCallsLimit, 'compact-number', 'Custom'),
        title: t('PlanCard.ApiCallsPerMonth'),
      },

      // Security & SLA

      {
        id: `scoped-api-keys-${plan.name}`,
        group: 'Security & SLA',
        value: getFormatedSize(
          plan.scopedKeysLimit,
          'compact-number',
          'Custom',
        ),
        title: t('PlanCard.ScopedApiKeys'),
      },
      {
        id: `iso-${plan.name}`,
        group: 'Security & SLA',
        value: getFormatedSize(plan.iso27001, '', 'Custom'),
        title: t('Plans.Form.Iso27001'),
      },
      {
        id: `uptime-${plan.name}`,
        group: 'Security & SLA',
        value: getFormatedSize(plan.uptime, '', 'Custom'),
        title: t('Plans.Form.Uptime'),
      },
      {
        id: `support-${plan.name}`,
        group: 'Security & SLA',
        value: getFormatedSize(plan.techSupport, '', 'Custom'),
        title: t('PlanCard.Response'),
      },

      // Teamwork

      {
        id: `team-members-${plan.name}`,
        group: 'Teamwork',
        value: getFormatedSize(
          plan.teamMembersLimit,
          'compact-number',
          'Custom',
        ),
        title: t('Spaces.TeamMembers'),
      },

      // Hosting

      {
        id: `hosting-${plan.name}`,
        group: 'Hosting',
        value: getFormatedSize(plan.hosting, '', 'Unlimited'),
      },

      // Backups

      {
        id: `backup-frequency-${plan.name}`,
        group: 'Backups',
        value: getFormatedSize(plan.backupFrequency, '', 'Unlimited'),
        title: t('Plans.Form.BackupFrequency'),
      },
      {
        id: `history-retention-${plan.name}`,
        group: 'Backups',
        value: getFormatedSize(plan.historyRetention, '', 'Unlimited'),
        title: t('Plans.Form.HistoryRetention'),
      },
      {
        id: `versioning-${plan.name}`,
        group: 'Backups',
        value: getFormatedSize(plan.versioning, '', 'Unlimited'),
        title: t('Plans.Form.Versioning'),
      },

      // Billing & Payments

      {
        id: `monthly-annual-${plan.name}`,
        group: 'Billing & Payments',
        value:
          plan.creditCard === 'v'
            ? true
            : getFormatedSize(plan.creditCard, '', 'Unlimited'),
        title: plan.creditCard === 'v' ? t('Plans.Form.CreditCard') : undefined,
      },
      {
        id: `monthly-annual-${plan.name}`,
        group: 'Billing & Payments',
        value: getFormatedSize(plan.monthlyAnnual, '', 'Unlimited'),
        title: t('Plans.Form.MonthlyAnnual'),
      },
      {
        id: `invoice-${plan.name}`,
        group: 'Billing & Payments',
        value: getFormatedSize(plan.invoice, '', 'Unlimited'),
        title: t('Plans.Form.Invoice'),
      },
      {
        id: `usage-dashboard-${plan.name}`,
        group: 'Billing & Payments',
        value: getFormatedSize(plan.usageDashboard, '', 'Unlimited'),
        title: t('Plans.Form.UsageDashboard'),
      },
    ];
    return { body: bodyDetails };
  }, [plan, t]);

  useEffect(() => {
    if (isExpanded) {
      setRenderedItems(planDetails.body);
    } else {
      setRenderedItems(planDetails.body.slice(0, 5));
    }
  }, [isExpanded, planDetails.body]);

  const handleCardClick = (event) => {
    if (!event.target.closest('button') && !disabled) {
      event.stopPropagation();
      handleSelected(plan);
    }
  };

  const groupedItems = renderedItems.reduce((acc, item) => {
    if (!acc[item.group]) {
      acc[item.group] = [];
    }
    acc[item.group].push(item);
    return acc;
  }, {});

  return (
    <div
      className={twMerge(
        'rounded-lg bg-transparent cursor-pointer relative group',
        disabled &&
          'bg-gray-200 hover:bg-gradient-transparent cursor-not-allowed',
        additionalClasses,
      )}
      onClick={handleCardClick}
      {...getTestProps(testId, 'container')}
    >
      {isCurrentPlan && (
        <StatusPill
          status={t('PlanHistory.CurrentPlan')}
          color={color === 'orange' ? 'orange' : 'blue'}
          containerClasses={twMerge(
            'absolute left-1/2 border-0 dark:border-0 -translate-x-1/2 -top-2.5 pt-1.5 pb-1 px-3 h-auto',
            'uppercase text-sm text-white dark:text-white rounded-lg whitespace-nowrap font-semibold z-10',
            `bg-${panelColor[color]} dark:bg-${panelColor[color]}`,
          )}
        />
      )}
      <div
        className={twMerge(
          'absolute w-[calc(100%+4px)] h-[calc(100%+4px)] m-[-2px] rounded-lg bg-transparent',
          selected && `border-${panelColor[color]}`,
          selected && 'border-4',
          disabled && 'group-hover:bg-gradient-transparent',
        )}
        {...getTestProps(testId, 'border-box')}
      />
      <div
        className={twMerge(
          'relative w-full rounded-lg overflow-hidden bg-white dark:bg-gray-900 dark:text-white h-full border',
          'border-slate-200 dark:border-gray-800 pb-1.5 group-hover:shadow-[0px_4px_20px_0px_#00000026]',
          selected &&
            `border-${panelColor[color]} dark:border-${panelColor[color]} text-${panelColor[color]}`,
          disabled && 'bg-[#f4f4f4]',
        )}
      >
        {/* Section: head */}
        <div
          className={twMerge(
            'flex flex-wrap items-center px-4 py-3 gap-2 border-t-[15px] relative',
            `border-${panelColor[disabled ? 'disabled' : color]}`,
            `text-${panelColor[disabled ? 'disabled' : color]}`,
          )}
          {...getTestProps(testId, 'panel')}
        >
          <span
            className={twMerge(
              'flex items-center font-bold text-2xl gap-2 uppercase',
              `text-${panelColor[color]}`,
            )}
            {...getTestProps(testId, 'name')}
          >
            {name}
            {limit && (
              <span
                className="text-indigo-950 dark:text-white text-base"
                {...getTestProps(testId, 'limit')}
              >
                {limit}
              </span>
            )}
          </span>
          <span
            className="font-bold text-lg ml-auto text-indigo-950 dark:text-white"
            {...getTestProps(testId, 'price')}
          >
            {price}
          </span>
        </div>
        {/* Section: sub */}
        <div className="p-4">
          <div
            className="font-bold text-indigo-950 dark:text-white"
            {...getTestProps(testId, 'sub')}
          >
            {t('Spaces.PlanIncludes')}:
          </div>
          {/* Section: body */}
          <div
            style={{
              maxHeight: isExpanded
                ? `${renderedItems.length * 60}px`
                : '240px',
            }}
            className="transition-all duration-300 ease-in-out overflow-hidden"
          >
            {Object.keys(groupedItems).map((group) => (
              <div
                key={group}
                className="border-b border-slate-200 dark:border-gray-800 last:border-none pt-5 pb-4"
              >
                <h3 className="font-bold text-lg leading-none pb-3">
                  {groupedItems[group][0].group}
                </h3>
                {groupedItems[group].map((el) => (
                  <TypeCardRow
                    key={el.id}
                    icon={
                      el.value ? (
                        <CheckCircleIcon
                          className={twMerge(
                            'w-4 mr-2',
                            `text-${panelColor[color]}`,
                          )}
                        />
                      ) : (
                        <span
                          className={twMerge(
                            'flex items-center justify-center mr-2 w-4 h-4 rounded-full',
                            `bg-${panelColor[color]}/25 text-${panelColor[color]}`,
                          )}
                        >
                          <CloseIcon className="w-1.5" />
                        </span>
                      )
                    }
                    title={el.title}
                    value={el.value || ''}
                    additionalValueClass={el.valueClass}
                    {...getTestProps(testId, `body-row-${el.id}`, 'testId')}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

SpaceTypeCard.propTypes = {
  /**
   *  Space name
   */
  name: PropTypes.string,
  /**
   *  Space price
   */
  price: PropTypes.string,
  /**
   *  If card is selected
   */
  selected: PropTypes.bool,
  /**
   *  Plan object
   */
  plan: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    spaceLicense: PropTypes.number,
    bandwidth: PropTypes.number,
    teamMembersLimit: PropTypes.number,
    scopedKeysLimit: PropTypes.number,
    apiCallsLimit: PropTypes.number,
    ctoLimit: PropTypes.number,
    ctdLimit: PropTypes.number,
    fileQuota: PropTypes.number,
  }),
  /**
   *  On select handler
   */
  handleSelected: PropTypes.func,
  /**
   *  Card color
   */
  color: PropTypes.string,
  /**
   *  If the plan is current space plan
   */
  isCurrentPlan: PropTypes.bool,
  /**
   *  Additional classes
   */
  additionalClasses: PropTypes.string,
  /**
   *  Test Id
   */
  testId: PropTypes.string,
};

SpaceTypeCard.defaultProps = {
  name: '',
  price: '',
  selected: false,
  plan: {},
  handleSelected: undefined,
  color: 'gray',
  additionalClasses: '',
  testId: '',
  limit: '',
  isCurrentPlan: false,
};

export default SpaceTypeCard;
