import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { CloseIcon } from '../../../../images/shapes';

const CancelButton = ({ label, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      buttonSize="sm"
      buttonColor="blueBordered"
      iconPosition="start"
      iconImage={<CloseIcon className="w-3 min-w-3" />}
      {...props}
    >
      {label || t('Global.Cancel')}
    </Button>
  );
};

export default CancelButton;

CancelButton.propTypes = {
  /**
   * Button label, default "Cancel"
   */
  label: PropTypes.string,
};

CancelButton.defaultProps = {
  label: '',
};
