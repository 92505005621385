import {
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { twMerge } from 'tailwind-merge';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Cropper, RectangleStencil } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';

// :: Context
import { ModalInstanceContext } from '../../contexts/ModalContext';

// :: Lib
import { getCropAttributes, getTestProps } from '../../lib/helpers';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';

// :: Images
import {
  CaretDownIcon,
  CenterIcon,
  DownloadCircleIcon,
  MaximizeIcon,
} from '../../images/shapes';

// :: Lib
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import { MediaConfigCropEvent } from '../../lib/flotiq-plugins/plugin-events/MediaConfigCropEvent';

// :: Components
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import HelpErrorTextsTemplate from '../../components/HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import ActionMenu from '../../components/ActionMenu/ActionMenu';
import Switch from '../../components/Switch/Switch';
import Tooltip from '../../components/Tooltip/Tooltip';
import CancelButton from '../../components/Button/predefined/CancelButton/CancelButton';
import SaveButton from '../../components/Button/predefined/SaveButton/SaveButton';

const PRESETS = [
  {
    key: 'square',
    label: 'Square (1:1)',
    name: 'Square',
    ratio: 1,
  },
  {
    key: 'horizontal',
    label: 'Horizontal (16:9)',
    name: 'Horizontal',
    ratio: 16 / 9,
  },
  {
    key: 'standard',
    label: 'Standard (4:3)',
    name: 'Standard',
    ratio: 4 / 3,
  },
];

const CustomLine = forwardRef(({ cropper, ...props }, ref) => {
  const {
    width: stencilWidth = 0,
    left,
    top,
  } = cropper.getStencilCoordinates(cropper.getState()) || {};
  const { width = 0, height = 0 } = cropper.getCoordinates() || {};

  return (
    <>
      <RectangleStencil ref={ref} cropper={cropper} {...props} />
      <div
        className="absolute text-green p-1 bg-blue m-1 transition-opacity	duration-500 pointer-events-none"
        style={{
          top,
          left,
          opacity: cropper.hasInteractions() ? 100 : 0,
        }}
      >
        {width.toFixed()} x {height.toFixed()},{' '}
        {((width / stencilWidth) * 100).toFixed()}%
      </div>
    </>
  );
});

const VariantModalForm = ({
  variant,
  variantsNames,
  media,
  errors,
  testId,
}) => {
  const { t } = useTranslation();
  const modalInstance = useContext(ModalInstanceContext);

  const cropperRef = useRef();
  const [coordinates, setCoordinates] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [aspectRatio, setAspectRatio] = useState();

  const config = useMemo(() => {
    const newConfig = {
      presets: PRESETS,
    };

    FlotiqPlugins.run(
      'flotiq.media.crop::config',
      new MediaConfigCropEvent({ config: newConfig }),
    );

    return newConfig;
  }, []);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .matches(/^\w+$/, t('MediaEdit.Variant.NameRegexp'))
          .required(t('Form.FormErrorNotBlank'))
          .test({
            name: 'name',
            message: t('MediaEdit.Variant.UniqueName'),
            test: (value) =>
              variantsNames
                .filter((name) => name !== variant?.name)
                .indexOf(value) < 0,
          }),
      }),
    [t, variant?.name, variantsNames],
  );

  const onSubmit = useCallback(
    (values) => {
      const cropValues = cropperRef.current?.getCoordinates();
      const cropTransform = {
        trim: {
          top: cropValues.top,
          left: cropValues.left,
          width: cropValues.width,
          height: cropValues.height,
        },
      };

      const newValues = {
        name: values.name,
        ...cropTransform,
      };

      modalInstance.resolve(newValues);
    },
    [modalInstance],
  );

  const onCancel = useCallback(() => {
    modalInstance.resolve();
  }, [modalInstance]);

  const initialCrop = useMemo(
    () => getCropAttributes(variant, media, true),
    [media, variant],
  );

  const center = useCallback(() => {
    if (cropperRef.current) {
      cropperRef.current.setCoordinates(({ coordinates, imageSize }) => ({
        left: imageSize.width / 2 - coordinates.width / 2,
        top: imageSize.height / 2 - coordinates.height / 2,
      }));
    }
  }, []);

  const maximize = useCallback(() => {
    if (cropperRef.current) {
      cropperRef.current.setCoordinates(({ imageSize }) => imageSize);
    }
  }, []);

  const onCropperChange = useCallback(() => {
    const coordinates = cropperRef.current?.getCoordinates();
    setCoordinates(coordinates);
  }, []);

  const onCoordinateInputChange = useCallback((type, value) => {
    if (cropperRef.current) {
      cropperRef.current.setCoordinates([
        ({ coordinates }) => ({
          ...coordinates,
          [type]: value || 0,
        }),
        ({ coordinates }) => {
          setCoordinates(coordinates);
        },
      ]);
    }
  }, []);

  const onAspectRatioChange = useCallback(
    (aspectRatio, formik) => {
      setAspectRatio(aspectRatio.ratio);
      setTimeout(onCropperChange);

      if (!formik.values.name)
        formik.handleChange({
          target: { name: 'name', value: aspectRatio.name },
        });
    },
    [onCropperChange],
  );

  const onDownload = useCallback(() => {
    const dataUrl = cropperRef.current?.getCanvas()?.toDataURL();

    const fileNameGroup = (media?.fileName || '').match(
      /(?<fileName>.*)\.(?<extenstion>[^.]+)$/,
    )?.groups;
    const fileName = fileNameGroup?.fileName || media.fileName || '';
    const name = fileName + '_crop';

    if (dataUrl) {
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = name;
      link.click();
    }
  }, [media.fileName]);

  const toggleAspetRatio = useCallback(
    (_, checked) => {
      if (checked && coordinates)
        setAspectRatio(coordinates.width / coordinates.height);
      else setAspectRatio();
    },
    [coordinates],
  );

  const settings = useMemo(
    () => [
      {
        onClick: maximize,
        tooltip: 'Maximize',
        icon: <MaximizeIcon className="w-4 text-stone-700 dark:text-white" />,
      },
      {
        onClick: center,
        tooltip: 'Center',
        icon: <CenterIcon className="w-4 text-stone-700 dark:text-white" />,
      },
      {
        onClick: onDownload,
        tooltip: 'Download',
        icon: <DownloadCircleIcon className="w-4 stone-700 dark:text-white" />,
      },
    ],
    [center, maximize, onDownload],
  );

  return (
    <Formik
      initialValues={{ name: variant?.name || '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <form
          id="variant-form"
          className="space-y-3 w-full h-full"
          onSubmit={formik.handleSubmit}
          noValidate={true}
        >
          <div className="flex items-end group">
            <Input
              name="name"
              label={t('Global.Name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.name || errors?.name}
              required
              additionalClasses="peer"
              additionalInputClasses="rounded-r-none border-r-0"
              additionalInputErrorClasses="mb-0"
              {...getTestProps(testId, 'name', 'testId')}
            />
            <ActionMenu
              menuItems={config?.presets || []}
              actionMenuPlacement="bottomRight"
              actionMenuPhonePlacement="bottomRight"
              button={<CaretDownIcon className="h-3 w-3" />}
              as={Button}
              buttonColor="blueBordered"
              iconColor="blue"
              onClick={(item) => onAspectRatioChange(item, formik)}
              additionalClasses={twMerge(
                'border border-slate-200 dark:border-slate-700 rounded-l-none',
                formik.errors.name && 'mb-4 border-red',
                'group-has-[input:focus]:border-blue',
                'rounded-l-none px-4',
              )}
              additionalPopoverPanelClasses="whitespace-nowrap"
              {...getTestProps(testId, 'aspect-menu', 'testId')}
            />
          </div>

          {isLoading && (
            <div
              className={twMerge(
                'absolute left-0 top-0 flex items-center justify-center',
                'h-full w-full pointer-events-none',
              )}
            >
              <Loader type="spinner-grid" />
            </div>
          )}

          {!isLoading && (
            <>
              <Switch
                name="aspect-ratio"
                checked={!!aspectRatio}
                label={t('MediaEdit.Variant.KeepRatio')}
                onChange={toggleAspetRatio}
                {...getTestProps(testId, 'aspect', 'testId')}
              />
              <details>
                <summary className="cursor-pointer hover:text-blue">
                  {t('MediaEdit.Variant.Transform.Details')}
                </summary>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mb-4">
                  {['Top', 'Left', 'Width', 'Height'].map((coordinate) => {
                    const name = coordinate.toLocaleLowerCase();
                    return (
                      <Input
                        key={name}
                        name={name}
                        label={t(`MediaEdit.Variant.Transform.${coordinate}`)}
                        value={coordinates?.[name] || 0}
                        type="number"
                        onChange={(e) => {
                          onCoordinateInputChange(name, e.target.value);
                        }}
                        additionalInputClasses="p-1 pl-3 h-auto rounded-sm"
                        additionalInputLabelClasses="mb-0.5"
                        updateCurrent
                        {...getTestProps(testId, name, 'testId')}
                      />
                    );
                  })}
                </div>
              </details>
            </>
          )}

          <div className="relative overflow-x-hidden">
            <Cropper
              ref={cropperRef}
              src={getMediaUrl(media)}
              className="max-w-full !max-h-[calc(100vh-25rem)] min-h-80 bg-transparent"
              aspectRatio={aspectRatio}
              onReady={() => setIsLoading(false)}
              defaultPosition={initialCrop}
              defaultSize={initialCrop}
              checkOrientation={false}
              stencilComponent={CustomLine}
              onChange={onCropperChange}
            />
            {!isLoading && (
              <div className="flex flex-wrap justify-center gap-2 mt-3">
                {settings.map((button) => (
                  <Tooltip
                    key={button.tooltip}
                    tooltip={t(`MediaEdit.Variant.Tooltip.${button.tooltip}`)}
                  >
                    <Button
                      type="button"
                      onClick={button.onClick}
                      iconImage={button.icon}
                      buttonColor="borderless"
                      additionalClasses="w-fit !p-2"
                      {...getTestProps(testId, button.tooltip, 'testId')}
                    />
                  </Tooltip>
                ))}
              </div>
            )}

            {errors?.transforms && (
              <HelpErrorTextsTemplate
                error={t('MediaEdit.Variant.Transform.Error')}
              />
            )}
          </div>
          <div
            className={twMerge(
              'w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5',
              'border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900',
            )}
          >
            <CancelButton
              onClick={onCancel}
              {...getTestProps(testId, 'cancel', 'testId')}
            />
            <SaveButton
              form="variant-form"
              disabled={isLoading}
              {...getTestProps(testId, 'save', 'testId')}
            />
          </div>
          <DirtyHandler isDirty />
        </form>
      )}
    </Formik>
  );
};

export default VariantModalForm;

VariantModalForm.propTypes = {
  /**
   * Media data
   */
  media: PropTypes.object.isRequired,
  /**
   * Array helpers from formik array field
   */
  variant: PropTypes.object,
  /**
   * User data
   */
  variantsNames: PropTypes.arrayOf(PropTypes.string),
  /**
   * Current file name without extension for media
   */
  fileName: PropTypes.string,
  /**
   * Errors for varaiant
   */
  errors: PropTypes.object,
  /**
   * Page test id
   */
  testId: PropTypes.string,
};

VariantModalForm.defaultProps = {
  variantsNames: [],
  fileName: '',
  testId: '',
};
