import { useContext } from 'react';
import PropTypes from 'prop-types';
import { getTestProps } from '../../../../lib/helpers';
import PopoverComponent from '../../../Popover/Popover';
import TopbarButton from './TopbarButton';
import { TopbarContext } from '../../Topbar';

const TopbarActionMenu = ({ children, disabled, ...props }) => {
  const { testId } = useContext(TopbarContext);

  if (!children) return null;

  return (
    <PopoverComponent
      popoverPanelPlacement={'bottomRight'}
      additionalPopoverPanelClasses={'z-30 border-blue dark:border-blue'}
      content={children}
      popoverButton={
        <TopbarButton
          as="div"
          label="..."
          buttonColor="blueBordered"
          additionalClasses="px-2"
          disabled={disabled}
          {...getTestProps(testId, 'action-menu-button', 'testId')}
        />
      }
      popoverButtonProps={props}
    />
  );
};

export default TopbarActionMenu;

TopbarActionMenu.propTypes = {
  /**
   * Acticon buttons
   */
  children: PropTypes.node,
  /**
   * If button is disabled
   */
  disabled: PropTypes.bool,
};

TopbarActionMenu.defaultProps = {
  disabled: false,
};
