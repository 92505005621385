import PropTypes from 'prop-types';
import { useContext } from 'react';
import { TopbarContext } from '../../Topbar';
import { getTestProps } from '../../../../lib/helpers';
import PopoverItem from '../../../Popover/PopoverItem/PopoverItem';

const TopbarActionButton = ({ color, label, disabled, ...props }) => {
  const { buttonsDisabled, testId } = useContext(TopbarContext);
  const isDisabled = disabled || buttonsDisabled;

  return (
    <PopoverItem
      label={label}
      disabled={isDisabled}
      color={color || 'blue'}
      {...props}
      {...getTestProps(testId, `${label}-action`, 'testId')}
    />
  );
};

export default TopbarActionButton;

TopbarActionButton.propTypes = {
  /**
   * Button label
   */
  label: PropTypes.node,
  /**
   * If button is disabled
   */
  disabled: PropTypes.bool,
};

TopbarActionButton.defaultProps = {
  label: '',
  disabled: false,
};
