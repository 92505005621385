import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState, useContext } from 'react';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import Heading from '../../components/Heading/Heading';
import LinkButton from '../../components/LinkButton/LinkButton';
import { getStripeURL, getTestProps } from '../../lib/helpers';
import { emailSchema, passwordSchema } from '../../lib/yupHelpers';
import { manageSubscription } from '../../lib/flotiq-client/api-helpers';
import useToken from '../../hooks/useToken';
import { PolandFlagIcon, UnitedKingdomFlagIcon } from '../../images/shapes';
import UserContext from '../../contexts/UserContext';
import SpaceSelector from '../../components/SpaceSelector/SpaceSelector';
import CancelButton from '../../components/Button/predefined/CancelButton/CancelButton';
import SaveButton from '../../components/Button/predefined/SaveButton/SaveButton';

const rolesOptionTranslationMap = {
  ROLE_HEADLESS_USER: (
    <Trans i18nKey={'Spaces.OrganizationRolesLabel.RoleHeadlessUser'} />
  ),
  ROLE_HEADLESS_ADMIN: (
    <Trans i18nKey={'Spaces.OrganizationRolesLabel.RoleHeadlessAdmin'} />
  ),
  ROLE_ADMIN: <Trans i18nKey={'Spaces.OrganizationRolesLabel.RoleAdmin'} />,
};

const UserForm = ({
  user,
  onSubmit,
  mode,
  owner,
  spacesData,
  userRoles,
  assignedRoles,
  testId,
}) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const { isRoleAdmin, isAdmin } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = useMemo(
    () =>
      yup.object({
        firstName: yup.string().required(t('Form.FormErrorNotBlank')),
        lastName: yup.string().required(t('Form.FormErrorNotBlank')),
        language: yup.string().required(t('Form.FormErrorNotBlank')),
        spaces: yup.array().of(
          yup.object({
            id: yup.string().required(t('Form.FormErrorNotBlank')),
            userRoles: yup.array().of(yup.string()),
          }),
        ),
        roles: yup
          .array()
          .min(1, t('PropertyForm.Errors.OptionsLength'))
          .required(t('PropertyForm.Errors.OptionsLength')),
        ...(mode === 'add'
          ? { email: emailSchema(t), plainPassword: passwordSchema(t) }
          : { username: yup.string().required(t('Form.FormErrorNotBlank')) }),
      }),
    [mode, t],
  );

  const organizationsDefaultSpace = useMemo(
    () =>
      spacesData
        ?.filter((space) => space.defaultSpace)
        ?.map((space) => ({
          id: space.id,
          userRoles: [],
        })),
    [spacesData],
  );

  const initialValues = useMemo(
    () =>
      mode === 'add'
        ? {
            email: '',
            plainPassword: '',
            firstName: '',
            lastName: '',
            language: 'en',
            spaces: organizationsDefaultSpace,
            roles: ['ROLE_HEADLESS_USER'],
          }
        : {
            firstName: user?.firstName,
            lastName: user?.lastName,
            username: user?.username,
            language: user?.language,
            spaces: user?.spaces?.map((space) => ({
              id: space.id,
              userRoles: assignedRoles
                .filter((role) => role.space?.id === space.id)
                .map(({ id }) => id),
            })),
            roles: user?.roles,
            ...(isRoleAdmin ? { paymentClientId: user?.paymentClientId } : {}),
          },
    [mode, organizationsDefaultSpace, user, isRoleAdmin, assignedRoles],
  );

  let buttonSaveText =
    mode === 'add' ? t('Global.Invite') : t('Global.SaveChanges');

  const onCancel = useCallback(
    (formik) => formik.resetForm({ values: initialValues }),
    [initialValues],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      setIsLoading(true);

      const { spaces, ...otherValues } = values;
      const spaceIds = spaces?.map(({ id }) => ({ id }));

      const headlessRoles = [];
      spaces?.forEach(({ userRoles }) =>
        userRoles.forEach((id) => {
          headlessRoles.push({ id });
        }),
      );

      const errors = await onSubmit({
        ...otherValues,
        ...(isAdmin && {
          spaces: spaceIds,
          headlessRoles,
        }),
      });

      formik.setStatus({ ...formik.status, errors });
      if (!errors || !Object.keys(errors).length) formik.resetForm({ values });

      setIsLoading(false);
    },
    [onSubmit, isAdmin],
  );

  const lngOptions = useMemo(
    () => [
      {
        value: 'en',
        searchString: t('UserForm.English'),
        label: (
          <div className="flex flex-row items-center">
            <UnitedKingdomFlagIcon className="w-5 h-5 mr-2" />
            {t('UserForm.English')}
          </div>
        ),
      },
      {
        value: 'pl',
        searchString: t('UserForm.Polish'),
        label: (
          <div className="flex flex-row items-center">
            <PolandFlagIcon className="w-5 h-5 mr-2" />
            {t('UserForm.Polish')}
          </div>
        ),
      },
    ],
    [t],
  );

  const spacesOptions = useMemo(
    () =>
      (isAdmin ? spacesData : user?.spaces)?.map(
        ({ id, name, planLimits }) => ({
          value: id,
          label: (
            <div className="flex items-center">
              {name}
              <div
                className={'bg-blue-300 rounded px-2 ml-2 dark:text-default'}
              >
                {planLimits?.visible_name}
              </div>
            </div>
          ),
          searchString: 'spaces',
        }),
      ) || [],
    [isAdmin, spacesData, user?.spaces],
  );

  const rolesOptions = useMemo(
    () => [
      { value: 'ROLE_HEADLESS_USER', label: 'Organization User' },
      { value: 'ROLE_HEADLESS_ADMIN', label: 'Organization Admin' },
      ...(isRoleAdmin ? [{ value: 'ROLE_ADMIN', label: 'Flotiq Admin' }] : []),
    ],
    [isRoleAdmin],
  );

  const OrganizationRolesSelect = useCallback(
    ({ formik }) => (
      <div>
        <Dropdown
          name="roles"
          label={t('UserForm.OrganizationRole')}
          options={rolesOptions}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.roles}
          disabled={isLoading || !isAdmin}
          error={formik.errors.roles || formik.status?.errors?.roles}
          additionalClasses="max-w-sm pr-2"
          hideSearch
          multiple
          required
          {...getTestProps(testId, 'roles', 'testId')}
        />
        <div className="flex flex-col pt-2">
          {formik.values.roles?.map((role) => {
            return (
              <label
                className="py-1 dark:text-white"
                key={`role-${role}-label`}
              >
                {rolesOptionTranslationMap[role]}
              </label>
            );
          })}
        </div>
      </div>
    ),
    [isAdmin, isLoading, rolesOptions, t, testId],
  );

  const spaceLinks = useMemo(
    () =>
      user?.spaces?.length > 0
        ? user.spaces.map(({ id, name }, idx) => (
            <LinkButton
              key={id}
              link={`/spaces/edit/${id}`}
              target="_blank"
              rel="noreferrer"
              buttonSize={'sm'}
              buttonColor={'borderless'}
              additionalClasses={'inline font-normal 2xl:text-sm'}
              noPaddings
            >
              {name} ↗{idx < user.spaces.length - 1 ? ', ' : ''}
            </LinkButton>
          ))
        : null,
    [user?.spaces],
  );

  const manageSubscriptionClick = useCallback(
    () => manageSubscription(jwt, user?.id, t),
    [jwt, t, user?.id],
  );

  const userRolesBySpace = useMemo(
    () =>
      ((isAdmin ? spacesData : user?.spaces) || []).reduce((roles, { id }) => {
        roles[id] =
          (isAdmin ? userRoles : assignedRoles)
            ?.filter(({ space }) => space?.id === id)
            ?.map((role) => ({ value: role.id, label: role.name })) || [];
        return roles;
      }, {}),
    [assignedRoles, isAdmin, spacesData, user, userRoles],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {(formik) => (
        <form
          id="user-form"
          className="max-w-3xl"
          onSubmit={formik.handleSubmit}
          noValidate={true}
          autoComplete="off"
        >
          <div className="flex flex-col gap-4 md:gap-6">
            <div className="text-xl md:text-2xl xl:text-3xl font-semibold dark:text-white">
              {t('UserForm.PersonalInformation')}
            </div>
            <div className="grid grid-cols-1 xs:grid-cols-2 items-center gap-4 md:gap-6 w-full">
              {mode === 'edit' && (
                <Input
                  autoComplete="off"
                  name={'username'}
                  placeholder={t('UserForm.Username')}
                  label={t('UserForm.Username')}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.username || formik.status?.errors?.username
                  }
                  additionalInputClasses={
                    isLoading ? '' : '!bg-white dark:!bg-gray-900'
                  }
                  disabled
                  required
                />
              )}

              {mode === 'add' && (
                <>
                  <Input
                    autoComplete="off"
                    name={'email'}
                    placeholder={'Email'}
                    label={'Email'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email || formik.status?.errors?.email}
                    required
                    additionalInputClasses={
                      isLoading ? '' : '!bg-white dark:!bg-gray-900'
                    }
                    {...getTestProps(testId, 'email', 'testId')}
                  />

                  <Input
                    autoComplete="new-password"
                    name="plainPassword"
                    type="password"
                    placeholder={t('Global.Password')}
                    label={t('Global.Password')}
                    value={formik.values.plainPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.plainPassword ||
                      formik.status?.errors?.plainPassword
                    }
                    required
                    showPasswordInfo
                    additionalInputClasses={
                      isLoading ? '' : '!bg-white dark:!bg-gray-900'
                    }
                    {...getTestProps(testId, 'plain-password', 'testId')}
                  />
                </>
              )}

              <Input
                name="firstName"
                placeholder={t('Global.FirstName')}
                label={t('Global.FirstName')}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.firstName || formik.status?.errors?.firstName
                }
                disabled={isLoading}
                additionalClasses="xs:row-start-2"
                required
                {...getTestProps(testId, 'first-name', 'testId')}
              />

              <Input
                name="lastName"
                placeholder={t('Global.LastName')}
                label={t('Global.LastName')}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.lastName || formik.status?.errors?.lastName
                }
                disabled={isLoading}
                additionalClasses="xs:row-start-2"
                required
                {...getTestProps(testId, 'last-name', 'testId')}
              />

              <Dropdown
                name="language"
                label={t('UserForm.Language')}
                options={lngOptions}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.language}
                disabled={isLoading}
                error={
                  formik.errors.language || formik.status?.errors?.language
                }
                additionalClasses="xs:row-start-3"
                hideSearch
                required
              />
            </div>

            <div className="flex flex-col">
              <Heading level={4} additionalClasses="dark:text-white pb-0">
                {t('Spaces.OrganizationRoles')}
              </Heading>
            </div>

            <OrganizationRolesSelect formik={formik} />

            <div className="flex flex-col">
              <Heading level={4} additionalClasses="dark:text-white pb-0">
                {t('Spaces.Spaces')}
              </Heading>
            </div>

            <div className="w-full">
              <FieldArray name="spaces">
                {(arrayHelpers) => (
                  <SpaceSelector
                    arrayHelpers={arrayHelpers}
                    spaces={spacesOptions}
                    userRolesBySpace={userRolesBySpace}
                    disabled={!isAdmin || isLoading}
                    error={
                      formik.errors.headlessRoles ||
                      formik.status?.errors?.headlessRoles ||
                      formik.errors.spaces ||
                      formik.status?.errors?.spaces
                    }
                    testId={testId}
                  />
                )}
              </FieldArray>
              {isRoleAdmin && spaceLinks && (
                <div className="inline-flex flex-wrap gap-1 mt-2 dark:text-white text-sm">
                  <Trans i18nKey="UserForm.SpaceLinks">
                    Go to space:
                    <span>{spaceLinks}</span>
                  </Trans>
                </div>
              )}
            </div>

            {isRoleAdmin && !owner && mode === 'edit' && (
              <>
                <Heading level={4} additionalClasses="mt-0 dark:text-white">
                  Admin
                </Heading>

                <div className="grid grid-cols-1 xs:grid-cols-2 items-center w-full">
                  <Input
                    name="paymentClientId"
                    label={t('Users.PaymentClientId')}
                    value={formik.values.paymentClientId || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.paymentClientId ||
                      formik.status?.errors?.paymentClientId
                    }
                    disabled={isLoading}
                    additionalClasses="pr-2"
                    helpText={
                      <div className="mt-1">
                        <Trans i18nKey="UserForm.PaymentLinks">
                          See
                          <Button
                            buttonSize={'sm'}
                            buttonColor={'borderless'}
                            additionalClasses={'inline font-normal 2xl:text-sm'}
                            onClick={manageSubscriptionClick}
                            noPaddings
                          >
                            Stripe manage panel
                          </Button>
                          <LinkButton
                            link={getStripeURL(
                              `/customers/${user?.paymentClientId || ''}`,
                            )}
                            target="_blank"
                            rel="noreferrer"
                            buttonSize={'sm'}
                            buttonColor={'borderless'}
                            additionalClasses={'inline font-normal 2xl:text-sm'}
                            noPaddings
                          >
                            Stripe customer panel
                          </LinkButton>
                        </Trans>
                      </div>
                    }
                  />
                </div>
              </>
            )}

            <div>
              <div className="flex flex-row items-center gap-2 md:gap-6">
                <SaveButton
                  form="user-form"
                  isSaving={isLoading}
                  label={buttonSaveText}
                  {...getTestProps(testId, 'submit', 'testId')}
                />
                {formik.dirty && (
                  <CancelButton
                    onClick={() => onCancel(formik)}
                    disabled={isLoading}
                    {...getTestProps(testId, 'cancel', 'testId')}
                  />
                )}
              </div>
              {formik.status?.errors?.global && (
                <div className="text-red mt-2 inline-flex">
                  <div className="block text-left">
                    {formik.status?.errors?.global}
                  </div>
                </div>
              )}
            </div>
          </div>
          <DirtyHandler />
        </form>
      )}
    </Formik>
  );
};

export default UserForm;

UserForm.propTypes = {
  /**
   * On submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * User data
   */
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
    language: PropTypes.string,
  }),
  /**
   * User mode between "add" or "edit"
   */
  mode: PropTypes.string,
  /**
   * Is profile page
   */
  owner: PropTypes.bool,
  /**
   * Organization spaces
   */
  spacesData: PropTypes.array,
  /**
   * All organization user roles
   */
  userRoles: PropTypes.array,
  /**
   * Roles assigned to user
   */
  assignedRoles: PropTypes.array,
  /**
   * User form test id
   */
  testId: PropTypes.string,
};

UserForm.defaultProps = {
  user: {},
  mode: 'edit',
  testId: '',
  owner: false,
  assignedRoles: [],
};
